import React from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout/Layout';

const cookiePolicy = props => {
  return (
    <Layout>
      <SEO title="Cookies politika - Monolit" noIndexNoFollow={true} />

      <div className="container padding-10-1-1">
        <h1>Cookies politika</h1>

        <p>
          Monolit d.o.o. koristi tehnologiju takozvanih internetskih cookiesa
          (hrvatski: kolačića).
        </p>
        <p>
          <em>
            Važna napomena: pravila kojima reguliramo privatnost i korištenje
            cookiesa nedavno smo unaprijedili, u skladu s novom europskom
            regulativom, stoga Vas ovim putem molimo da se upoznate s njom.
            Ukoliko želite onemogućiti korištenje cookiesa, upute se nalaze na
            dnu ove stranice.
          </em>
        </p>
        <h2>Zašto koristimo cookiese (hrv. kolačiće)?</h2>
        <p>
          Tehnologiju cookiesa koristimo kako bismo mogli pružati našu online
          uslugu, analizirati korištenje sadržaja, nuditi oglašivačka rješenja,
          kao i za ostale funkcionalnosti koje ne bismo mogli pružati bez
          korištenja cookiesa. Detaljan popis cookiesa potražite u nastavku ovog
          teksta. Podaci pohranjeni korištenjem cookiesa na izdanjima monolit.hr
          internetskih stranica ne sadrže osobne detalje iz kojih bi se mogao
          utvrditi identitet korisnika.
        </p>
        <h2>Što je cookie i čemu služi?</h2>
        <p>
          Najjednostavnije rečeno, cookie je zapis koju web stranice pohranjuju
          i / ili dobivaju iz memorije vašeg uređaja, kroz preglednik koji
          koristite. Ukoliko ste, na primjer, na nekoj web stranici odabrali
          hrvatski jezik kao željeni jezik komunikacije, podatak o vašem odabiru
          bit će pohranjen u obliku cookijea. Većina cookiesa ima vremenski rok
          trajanja, a svaki se može obrisati ili onemogućiti u postavkama vašeg
          preglednika.
        </p>
        <p>
          Radi pamćenja vaših postavki i navika, korištenje cookiesa pomaže u
          brzini i sigurnosti pristupanja stranicama, a u mnogim slučajevima
          nužni su za normalno prikazivanje sasdržaja i funkcioniranje stranice.
          Takozvani first-party cookies (kolačići prve strane) sadrže podatke
          stranice kojoj pristupate, a third-party cookies (kolačići treće
          strane) sadrže podatke trećih servisa, na primjer, alati društvenih
          mreža na člancima, za lakše dijeljenje članaka.
        </p>
        <h2>Kakve cookiese koristimo?</h2>
        <p>
          Monolit.hr tehnologiju cookiesa koristi odgovorno, u skladu sa važećom
          nacionalnom i europskom regulativom, isključivo za unaprijeđenje vašeg
          korisničkog iskustva i omogućavanje pružanja naših usluga. Cookiese
          koji se koriste na našim izdanjima uglavnom spadaju u tri osnovne
          kategorije:
        </p>
        <ol>
          <li>
            <strong>Strogo potrebni cookies</strong>
            <br />
            Cookies bez kojih rad stranica monolit.hr ne bi bio moguć. Rok
            trajanja cookiesa iznosi tri godine.
          </li>
          <li>
            <strong>Funkcionalni cookies</strong>
            <br />
            Cookies koji nam omogućavaju da pamtimo vaše postavke i pružamo
            bolju uslugu. Ovi Cookiese prikupljaju anonimne podatke i ne mogu
            pratiti vaše posjete drugim internetskim stranicama. Rok trajanja
            cookiesa iznosi tri godine.
          </li>
          <li>
            <strong>Ostali cookies</strong>
            <br />
            Ostali cookies, na primjer društvenih mreža poput Facebooka ili
            Twittera. Ovim cookiesima ne upravlja Monolit.hr
          </li>
        </ol>
        {/* <h2>Popis cookiesa koje koristimo</h2>
        <ul>
          <li>
            <strong>
              Google:&nbsp;Google analitika
              <br />
            </strong>
            interna analitika posjećenosti
            <strong>
              <br />
            </strong>
          </li>
          <li>
            <strong>
              Facebook:&nbsp;Facebook pixel
              <br />
            </strong>
            interna analitika posjećenosti
          </li>
          <li>
            <strong>
              Facebook: Facebook
              <br />
            </strong>
            sadržaj i analitika
          </li>
          <li>
            <strong>
              Facebook: Instagram
              <br />
            </strong>
            sadržaj i analitika
          </li>
        </ul> */}
        <p>
          Marketinški cookies poput Google Doubleclicka bilježe posjete našim
          internetskim stranicama. Ti podaci koriste se kako bismo ograničili
          broj prikazivanja oglasa, unaprijedili i optimizirali učinkovitost
          marketinških kampanja, te dostavljali oglase koji su u skladu s vama i
          vašim interesima. Ove podatke možemo podijeliti s trećim osobama poput
          oglašivača. Rok trajanja ovih kolačića može biti više od godine dana
          ili do uklanjanja kolačića u internetskom pregledniku.
        </p>
        <p>
          Analitički cookies poput Gemius Audiencea i Google Analyticsa Telegram
          media grupi omogućavaju prepoznavanje i prebrojavanje korisnika, kao i
          prikupljanje podataka o tome kako se stranice koriste – na primjer,
          koje stranice korisnici najčešće otvaraju i prima li korisnik
          obavijesti o greškama od pojedinih stranica). To nam pomaže
          unaprijediti rad internetskih stranica, primjerice, da vam osiguramo
          lako pronalaženje svega što tražite. Trajanje ovih cookiesa može biti
          više od godine dana ili do uklanjanja u postavkama.
        </p>
        <p>
          Napomena: Ukoliko naša izdanja počnu koristiti dodatne cookiese koji
          trenutno nisu navedeni, o tome ćemo vas obavijestiti kroz ažuriranje
          ove stranice.
        </p>
        <h2>Kako mogu obrisati ili blokirati cookiese?</h2>
        <p>
          Suglasnost za cookiese na našim izdanjima možete povući kada god
          želite. Cookiese možete obrisati ili blokirati kroz svoj internetski
          preglednik ili koristeći specijalizirane softvere za to. Napominjemo
          kako onemogućavanje svih cookiesa može uzrokovati probleme u
          funkcioniranju većine stranica, pa tako i izdanja Telegram media
          grupe. Upute za postavke cookiesa u vašem pregledniku možete pronaći
          na sljedećim adresama:
        </p>
        <ul>
          <li>
            <a
              href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=hr"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google Chrome
            </a>
          </li>
          <li>
            <a
              href="https://support.mozilla.org/hr/kb/Kola%C4%8Di%C4%87i"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mozilla Firefox
            </a>
          </li>
          <li>
            <a
              href="https://support.microsoft.com/hr-hr/help/17442/windows-internet-explorer-delete-manage-cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              Internet Explorer
            </a>
          </li>
          <li>
            <a
              href="https://support.apple.com/hr-hr/guide/safari/sfri11471/mac"
              target="_blank"
              rel="noopener noreferrer"
            >
              Safari
            </a>
          </li>
        </ul>
        <hr />
        <p>
          Ukoliko imate dodatnih pitanja o pravilima privatnosti i korištenju
          cookiesa, obratite nam se na info@monolit.hr.
        </p>
      </div>
    </Layout>
  );
};

export default cookiePolicy;
